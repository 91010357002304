import { SerializedFarm } from 'state/types'
import fetchPublicFarmData from './fetchPublicFarmData'

const fetchFarm = async (farm: SerializedFarm): Promise<SerializedFarm> => {
  const farmPublicData = await fetchPublicFarmData(farm)

  const out = { ...farm, ...farmPublicData }
  if (farm.isSubchef) {
    out.isOtherReward = {
      ...farm.isOtherReward,
      rewardPerSec: farmPublicData.rewardPerSec,
    }
  }
  return out
}

export default fetchFarm
