import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    pid: 0,
    stakingToken: serializedTokens.glow,
    earningToken: serializedTokens.glow,
    contractAddress: {
      245022926: '0x261d0B0F4E7E8BAe5D4d55516F651e2b5F86c9AC',
      245022934: '',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.014',
    sortOrder: 1,
    isFinished: false,
    isMaster: true,
  },
  // {
  //   sousId: 1,
  //   pid: 0,
  //   stakingToken: serializedTokens.glow,
  //   earningToken: serializedTokens.table,
  //   contractAddress: {
  //     245022926 '0xd381E79fC12462cd1BdDE42018ffC935aAeBE6FD',
  //     245022934: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '0.00058',
  //   sortOrder: 2,
  //   isFinished: false,
  //   isMaster: false,
  // },
  // {
  //   sousId: 2,
  //   pid: 27,
  //   stakingToken: serializedTokens.grim,
  //   earningToken: serializedTokens.glow,
  //   contractAddress: {
  //     245022926: '0x53e986884c55c9AEDB7f003583f350EE789505D0',
  //     245022934: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '0.012',
  //   sortOrder: 3,
  //   isFinished: false,
  //   isMaster: true,
  // },
  // {
  //   sousId: 20,
  //   stakingToken: serializedTokens.syrup,
  //   earningToken: serializedTokens.cake,
  //   contractAddress: {
  //     97: '',
  //     56: '0x555Ea72d7347E82C614C16f005fA91cAf06DCB5a',
  //   },
  //   poolCategory: PoolCategory.BINANCE,
  //   harvest: true,
  //   tokenPerBlock: '0.5',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
  // {
  //   sousId: 19,
  //   stakingToken: serializedTokens.cake,
  //   earningToken: serializedTokens.syrup,
  //   contractAddress: {
  //     97: '',
  //     56: '0x326D754c64329aD7cb35744770D56D0E1f3B3124',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '0.0041',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
]

export default pools
