import BigNumber from 'bignumber.js'
// import { getAddress} from 'utils/addressHelpers'
// import { useMasterchef , useSubchef } from 'hooks/useContract'
// import { farmsConfig } from 'config/constants'
import { DEFAULT_GAS_LIMIT } from 'config'
import getGasPrice from 'utils/getGasPrice'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

export const stakeNFTFarm = async (contract, pid, amounts) => {
  const gasPrice = getGasPrice()
  const value = amounts.map((val) => new BigNumber(val).toString())

  const tx = await contract.stakeNFT(pid, value, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeNFTFarm = async (contract, pid, amounts) => {
  const gasPrice = getGasPrice()
  const value = amounts.map((val) => new BigNumber(val).toString())

  const tx = await contract.unstakeNFT(pid, value, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

// export const harvestNFTFarm = async (contract, pid, farmPid) => {
//   const gasPrice = getGasPrice()
//   if (farmPid === 0) {
//     const tx = await contract.withdraw(pid, '0', { ...options, gasPrice })
//     const receipt = await tx.wait()
//     return receipt.status
//   }

//   if (farmPid === 1000) {
//     const tx = await contract.emergencyWithdraw(pid, { ...options, gasPrice })
//     const receipt = await tx.wait()
//     return receipt.status
//   }

//   const tx = await contract.deposit(pid, '0', { ...options, gasPrice })
//   const receipt = await tx.wait()
//   return receipt.status
// }
