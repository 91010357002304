import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import subchefABI from 'config/abi/subchef.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { SerializedFarmConfig } from 'config/constants/types'
import { BIG_TEN } from 'utils/bigNumber'
import tokens from 'config/constants/tokens'

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    const chefAddress = farm.isSubchef ? getAddress(farm.isOtherReward.subChef) : masterChefAddress
    return { address: lpContractAddress, name: 'allowance', params: [account, chefAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefCalls = []
  const subChefCalls = []
  farmsToFetch.forEach((farm) => {
    const pID = farm.isSubchef ? farm.isOtherReward.subPid : farm.pid
    if (farm.isSubchef) {
      subChefCalls.push({
        address: getAddress(farm.isOtherReward.subChef),
        name: 'userInfo',
        params: [pID, account],
      })
    } else {
      masterChefCalls.push({
        address: getMasterChefAddress(),
        name: 'userInfo',
        params: [pID, account],
      })
    }
  })
  const rawMasterChefResults = await multicall(masterchefABI, masterChefCalls)
  const rawSubChefResults = await multicall(subchefABI, subChefCalls)
  const rawStakedBalances = [...rawMasterChefResults, ...rawSubChefResults]

  // const masterChefAddress = getMasterChefAddress()

  // const calls = farmsToFetch.map((farm) => {
  //   const chefAddress = (farm.isSubchef ? getAddress(farm.isOtherReward.subChef) : masterChefAddress)
  //   const pID = (farm.isSubchef ? farm.isOtherReward.subPid : farm.pid)
  //   return {
  //     address: chefAddress,
  //     name: 'userInfo',
  //     params: [pID, account],
  //   }
  // })

  // const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  // let pid = 0
  // let chefABI

  // const pid = farmsToFetch.map((farm) => (farm.isSubchef ? farm.isOtherReward.subPid : farm.pid))

  // const calls = farmsToFetch.map((farm) => {
  //   const chefAddress = (farm.isSubchef ? getAddress(farm.isOtherReward.subChef) : getMasterChefAddress())
  //   chefABI = (farm.isSubchef ? subchefABI :  masterchefABI )
  //    pid = (farm.isSubchef ? farm.isOtherReward.subPid : farm.pid)
  //   const pending = (farm.isSubchef ? 'pendingReward' : 'pendingGlow')

  //   return {
  //     address: chefAddress,
  //     name: pending,
  //     params: [pid, account],
  //   }
  // })

  // const rawEarnings = await multicall(chefABI, calls)
  const masterChefCalls = []
  const subChefCalls = []
  const chefMap = []
  let masterCounter = 0
  let subCounter = 0

  farmsToFetch.forEach((farm) => {
    const pID = farm.isSubchef ? farm.isOtherReward.subPid : farm.pid

    if (farm.isSubchef) {
      chefMap.push({ list: 'sub', index: subCounter })
      subCounter += 1
      subChefCalls.push({
        address: getAddress(farm.isOtherReward.subChef),
        name: 'pendingReward',
        params: [pID, account],
      })
    } else {
      chefMap.push({ list: 'master', index: masterCounter })
      masterCounter += 1
      masterChefCalls.push({
        address: getMasterChefAddress(),
        name: 'pendingReward',
        params: [pID, account],
      })
    }
  })
  const rawMasterChefResults = await multicall(masterchefABI, masterChefCalls)
  const rawSubChefResults = await multicall(subchefABI, subChefCalls)
  const parsedEarnings = []

  farmsToFetch.forEach((farm, i) => {
    const current = chefMap[i]
    if (current.list === 'master') {
      parsedEarnings.push(
        new BigNumber(rawMasterChefResults[current.index][0]._hex).div(BIG_TEN.pow(tokens.cake.decimals)).toJSON(),
      )
    } else {
      parsedEarnings.push(
        new BigNumber(rawSubChefResults[current.index][0]._hex)
          .div(BIG_TEN.pow(farm.isOtherReward.rewardToken.decimals))
          .toJSON(),
      )
    }
  })
  return parsedEarnings
}

// export const fetchFarmDaysSinceDeposit = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
//   const masterChefCalls = []
//   const subChefCalls = []
//   farmsToFetch.forEach((farm) => {
//     const pID = farm.isSubchef ? farm.isOtherReward.subPid : farm.pid
//     if (farm.isSubchef) {
//       subChefCalls.push({
//         address: getAddress(farm.isOtherReward.subChef),
//         name: 'getUserInfo',
//         params: [pID, account],
//       })
//     } else {
//       masterChefCalls.push({
//         address: getMasterChefAddress(),
//         name: 'getUserInfo',
//         params: [pID, account],
//       })
//     }
//   })
//   const rawMasterChefResults = await multicall(masterchefABI, masterChefCalls)
//   const rawSubChefResults = await multicall(subchefABI, subChefCalls)
//   const rawDaysSinceDeposit = [...rawMasterChefResults, ...rawSubChefResults]

//   // const calls = farmsToFetch.map((farm) => {
//   //   const chefAddress = (farm.isSubchef ? getAddress(farm.isOtherReward.subChef) : getMasterChefAddress())
//   //   const pID = (farm.isSubchef ? farm.isOtherReward.subPid : farm.pid)
//   //   return {
//   //     address: chefAddress,
//   //     name: 'getUserInfo',
//   //     params: [pID, account],
//   //   }
//   // })

//   // const rawDaysSinceDeposit = await multicall(masterchefABI, calls)
//   const parsedDaysSinceDeposit = rawDaysSinceDeposit.map((data) => {
//     return new BigNumber(data.daysSinceDeposit._hex).toJSON()
//   })
//   return parsedDaysSinceDeposit
// }

// export const fetchFarmIlp = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
//   const masterChefAddress = getMasterChefAddress()

//   const calls = farmsToFetch.map((farm) => {
//     const pID = farm.isSubchef ? 0 : farm.pid
//     return {
//       address: masterChefAddress,
//       name: 'checkForIL',
//       params: [pID, account],
//     }
//   })

//   const rawIlp = await multicall(masterchefABI, calls)
//   const parsedIlp = {}
//   rawIlp.forEach((data, index) => {
//     parsedIlp[farmsToFetch[index].pid] = new BigNumber(data.extraGlow._hex).toJSON()
//   })
//   // if ( pid === 1000 )
//   return parsedIlp
// }
