import { Token } from '@neonswap/testnet-sdk'
import BigNumber from 'bignumber.js'
import presaleAbi from 'config/abi/presale.json'
import vestingAbi from 'config/abi/vesting.json'
import { Address, PresaleConfig } from 'config/constants/types'
import moment from 'moment'
import { getAddress } from 'utils/addressHelpers'
import { getBalanceAmount } from 'utils/formatBalance'
import { Call, multicallv2 } from 'utils/multicall'
import { SaleLimits, SalePublicData, SaleStats, SaleTimings } from './types'

type SalePublicDataWithoutBuyers = Omit<SalePublicData, 'buyers'>
type BuyersData = {
  presaleBuyers?: number
  privateSaleBuyers?: number
  totalBuyers: number
}

export async function fetchSaleData(config: PresaleConfig): Promise<{
  presale?: SalePublicDataWithoutBuyers
  privateSale?: SalePublicDataWithoutBuyers
  buyers?: BuyersData
}> {
  let presale: SalePublicDataWithoutBuyers
  let privateSale: SalePublicDataWithoutBuyers

  if (config.saleType === 'public' || config.saleType === 'both') {
    const info = await fetchSaleInfo(config.presale.address, config.token)
    const raisedBalance = await fetchRaisedBalance(config.presale.address, config.token, config.presale.qToken)
    const timing = await fetchSaleTimes(config.presale.address)
    presale = { ...info, ...raisedBalance, ...timing }
    if (config.presale.vestingEnabled) {
      const vestingTiming = await fetchSaleVestingTimes(config.presale.vestingAddress)
      presale.distStartTime = vestingTiming.distStartTime
      presale.timeToDistStart = vestingTiming.timeToDistStart
    }
  }

  if (config.saleType === 'private' || config.saleType === 'both') {
    const info = await fetchSaleInfo(config.privateSale.address, config.token)
    const raisedBalance = await fetchRaisedBalance(config.privateSale.address, config.token, config.privateSale.qToken)
    const timing = await fetchSaleTimes(config.privateSale.address)
    privateSale = { ...info, ...raisedBalance, ...timing }
    if (config.privateSale.vestingEnabled) {
      const vestingTiming = await fetchSaleVestingTimes(config.privateSale.vestingAddress)
      presale.distStartTime = vestingTiming.distStartTime
      presale.timeToDistStart = vestingTiming.timeToDistStart
    }
  }

  const buyers = await fetchBuyers(config)

  // console.log({ presale, privateSale, buyers })

  return {
    presale,
    privateSale,
    buyers,
  }
}

/*
 * Fetch sale info from presale contract
 */
async function fetchSaleInfo(address: Address, token: Token): Promise<SaleLimits> {
  const saleAddress = getAddress(address)

  const calls: Call[] = [
    {
      address: saleAddress,
      name: 'MAX_BUY',
    },
    {
      address: saleAddress,
      name: 'MIN_BUY',
    },
    {
      address: saleAddress,
      name: 'tokenPrice',
    },
    {
      address: saleAddress,
      name: 'liqAdded',
    },
  ]
  const [_maxBuy, _minBuy, _tokenPrice, _liqAdded] = await multicallv2(presaleAbi, calls)
  // console.log({ _maxBuy, _minBuy, _tokenPrice })

  const maxBuy = getBalanceAmount(new BigNumber(_maxBuy), token.decimals)
  const minBuy = getBalanceAmount(new BigNumber(_minBuy), token.decimals)
  const tokenPrice = getBalanceAmount(new BigNumber(_tokenPrice), token.decimals)

  return {
    maxBuy,
    minBuy,
    maxBuyQtoken: maxBuy.dividedBy(tokenPrice),
    minBuyQtoken: minBuy.dividedBy(tokenPrice),
    liqAdded: _liqAdded[0],
  }
}

/*
 * Fetch raised balance from presale contract
 */
async function fetchRaisedBalance(address: Address, token: Token, qToken: Token): Promise<SaleStats> {
  const saleAddress = getAddress(address)

  const calls: Call[] = [
    {
      address: saleAddress,
      name: 'totalCollected',
    },
    {
      address: saleAddress,
      name: 'saleTokens',
    },
    {
      address: saleAddress,
      name: 'tokenPrice',
    },
  ]
  const [_totalRaised, _saleTokens, _tokenPrice] = await multicallv2(presaleAbi, calls)
  // console.log({ _totalRaised, _saleTokens, _tokenPrice })

  const totalRaised = new BigNumber(_totalRaised).div(10 ** qToken.decimals)
  const saleTokens = new BigNumber(_saleTokens).div(10 ** token.decimals)
  const tokenPrice = new BigNumber(_tokenPrice).div(10 ** token.decimals)

  return {
    totalRaised,
    saleTokens,
    tokenPrice,
    tokenPriceInverse: new BigNumber(1).div(tokenPrice),
    totalCap: saleTokens.div(tokenPrice),
  }
}

/*
 * Fetch buyers from presale contract
 */
async function fetchBuyers(config: PresaleConfig): Promise<BuyersData> {
  const presaleAddress = getAddress(config.presale.address)
  const privateSaleAddress = getAddress(config.privateSale.address)

  const calls: Call[] = [
    {
      address: presaleAddress,
      name: 'totalBuyers',
    },
    {
      address: privateSaleAddress,
      name: 'totalBuyers',
    },
  ]
  const [_presaleBuyers, _privateSaleBuyers] = await multicallv2(presaleAbi, calls, { requireSuccess: false })
  const presaleBuyers = new BigNumber(_presaleBuyers).toNumber()
  const privateSaleBuyers = new BigNumber(_privateSaleBuyers).toNumber()

  let totalBuyers
  if (config.saleType === 'public') totalBuyers = presaleBuyers
  else if (config.saleType === 'private') totalBuyers = privateSaleBuyers
  else totalBuyers = presaleBuyers + privateSaleBuyers

  return {
    presaleBuyers,
    privateSaleBuyers,
    totalBuyers,
  }
}

/*
 * Fetch sale times from presale contract
 */
async function fetchSaleTimes(address: Address): Promise<SaleTimings> {
  const saleAddress = getAddress(address)

  const calls: Call[] = [
    {
      address: saleAddress,
      name: 'startTime',
    },
    {
      address: saleAddress,
      name: 'endTime',
    },
    {
      address: saleAddress,
      name: 'saleEnded',
    },
    {
      address: saleAddress,
      name: 'distStartTime',
    },
  ]
  const [_startTime, _endTime, _saleEnded, _distStartTime] = await multicallv2(presaleAbi, calls, {
    requireSuccess: false,
  })

  const startTime = moment.unix(new BigNumber(_startTime).toNumber())
  const endTime = moment.unix(new BigNumber(_endTime).toNumber())

  const distStartTime = moment.unix(new BigNumber(_distStartTime).toNumber())

  return {
    startTime: startTime.unix(),
    endTime: endTime.unix(),
    timeToEnd: _saleEnded[0] ? -1 : endTime.diff(moment(), 'seconds'),

    distStartTime: distStartTime.unix(),
    timeToDistStart: distStartTime.diff(moment(), 'seconds'),
  }
}

/*
 * Fetch sale times from vesting contract
 */
async function fetchSaleVestingTimes(
  address: Address,
): Promise<Pick<SaleTimings, 'distStartTime' | 'timeToDistStart'>> {
  const vestingAddress = getAddress(address)

  const calls: Call[] = [
    {
      address: vestingAddress,
      name: 'startTimestamp',
    },
  ]
  const [_distStartTime] = await multicallv2(vestingAbi, calls)

  const distStartTime = moment.unix(new BigNumber(_distStartTime).toNumber())

  return {
    distStartTime: distStartTime.unix(),
    timeToDistStart: distStartTime.diff(moment(), 'seconds'),
  }
}
