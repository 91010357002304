/* eslint-disable prefer-destructuring */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { presaleConfigs } from 'config/constants/presale'
import { PresaleConfig } from 'config/constants/types'
import { deserializeToken } from 'state/user/hooks/helpers'
import { fetchSaleData } from './fetchSaleData'
import { fetchSaleUserData } from './fetchSaleUserData'
import { LaunchPadState } from './types'

const initialState: LaunchPadState = {}
presaleConfigs.forEach((config) => {
  initialState[config.token.symbol] = {
    config: {
      ...config,
      token: deserializeToken(config.token),
    },
    presale: {},
    privateSale: {},
  }
})

// Async thunks
export const fetchSalePublicDataAsync = createAsyncThunk(
  'launchpad/fetchSalePublicData',
  async (saleName: string, { getState }) => {
    const config: PresaleConfig = (getState() as any).launchpad[saleName].config

    const res = await fetchSaleData(config)
    return res
  },
)

export const fetchSaleUserDataAsync = createAsyncThunk(
  'launchpad/fetchSaleUserData',
  async ({ account, saleName }: { account: string; saleName: string }, { getState }) => {
    const config: PresaleConfig = (getState() as any).launchpad[saleName].config
    const res = await fetchSaleUserData(account, config)
    return res
  },
)

export const lauchpadSlice = createSlice({
  name: 'LaunchPad',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSalePublicDataAsync.fulfilled, (state, { payload, meta: { arg } }) => {
      state[arg].totalBuyers = payload.buyers.totalBuyers
      state[arg].presale.publicData = {
        ...payload.presale,
        buyers: payload.buyers.totalBuyers,
      }
      state[arg].privateSale.publicData = {
        ...payload.privateSale,
        buyers: payload.buyers.privateSaleBuyers,
      }
    })

    builder.addCase(fetchSaleUserDataAsync.fulfilled, (state, { payload, meta: { arg } }) => {
      state[arg.saleName].presale = {
        ...state[arg.saleName].presale,
        userData: payload.presale,
      }
      state[arg.saleName].privateSale = {
        ...state[arg.saleName].privateSale,
        userData: payload.privateSale,
      }
    })
  },
})

export default lauchpadSlice.reducer
