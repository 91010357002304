import { Token, ChainId } from '@neonswap/testnet-sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wbnb: new Token(
    MAINNET as any,
    '0x11adC2d986E334137b9ad0a0F290771F31e9517F',
    18,
    'WNEON',
    'Wrapped NEON',
    'https://neonswap.io/',
  ),
  // bnb here points to the wbnb contract. Wherever the currency NEON is required, conditional checks for the symbol 'NEON' can be used
  bnb: new Token(
    MAINNET as any,
    '0x11adC2d986E334137b9ad0a0F290771F31e9517F',
    18,
    'NEON',
    'NEON',
    'https://neonswap.io/',
  ),
  cake: new Token(
    MAINNET as any,
    '0xD69891f7e33b0eC98c0A9E9b5831585c32f76042',
    18,
    'GLOW',
    'glow Token',
    'https://neonswap.io/',
  ),
  glow: new Token(
    MAINNET as any,
    '0xD69891f7e33b0eC98c0A9E9b5831585c32f76042',
    18,
    'GLOW',
    'glow Token',
    'https://neonswap.io/',
  ),
  // syrup: new Token(
  //   MAINNET as any,
  //   '0x12FfFae0315B6d20279a1222A5C07FBbd7fc7c0D',
  //   18,
  //   'STUB',
  //   'Stub Token',
  //   'https://neonswap.io/',
  // ),
  busd: new Token(
    MAINNET as any,
    '0x512E48836Cd42F3eB6f50CEd9ffD81E0a7F15103',
    6,
    'USDC',
    'USD Coin',
    'https://www.centre.io/',
  ),
  // qsd: new Token(
  //   MAINNET as any,
  //   '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
  //   18,
  //   'QSD',
  //   'QIAN',
  //   'https://chemix.io/home',
  // ),
  
}

export const testnetTokens = {
  wbnb: new Token(
    TESTNET as any,
    '0x11adC2d986E334137b9ad0a0F290771F31e9517F',
    18,
    'WNEON',
    'Wrapped NEON',
    'https://www.binance.com/',
  ),
  cake: new Token(
    TESTNET as any,
    '0x8fA70ce232A1b085082d3ee30d7C4Aed0B252bf9',
    18,
    'GLOW',
    'NeonSwap Token',
    'https://neonswap.io/',
  ),
  busd: new Token(
    TESTNET as any,
    '0xE7fdBb572b1d705e4c9645D3BeECfD11592b08A5',
    6,
    'USDC',
    'USD Coin',
    'https://www.paxos.com/busd/',
  ),
  syrup: new Token(
    TESTNET as any,
    '0x12FfFae0315B6d20279a1222A5C07FBbd7fc7c0D',
    18,
    'STUB',
    'STUB Token',
    'https://neonswap.io/',
  ),
  qsd: new Token(
    TESTNET as any,
    '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
    18,
    'QSD',
    'QIAN',
    'https://chemix.io/home',
  ),
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    //   return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    // }, {})
    return testnetTokens
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
