import BigNumber from 'bignumber.js'
import masterchefABI from 'config/abi/masterchef.json'
import subchefABI from 'config/abi/subchef.json'
import erc20 from 'config/abi/erc20.json'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'
import { SerializedFarm, SerializedBigNumber } from '../types'

const erc20calls = async (farm: SerializedFarm) => {
  const { lpAddresses, token, quoteToken, isSubchef, isOtherReward } = farm
  const lpAddress = getAddress(lpAddresses)
  const chefAddress = isSubchef ? getAddress(isOtherReward.subChef) : getMasterChefAddress()
  const calls = [
    // Balance of token in the LP contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: quoteToken.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of LP tokens in the master chef contract
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [chefAddress],
    },
    // Total supply of LP tokens
    {
      address: lpAddress,
      name: 'totalSupply',
    },
    // Token decimals
    {
      address: token.address,
      name: 'decimals',
    },
    // Quote token decimals
    {
      address: quoteToken.address,
      name: 'decimals',
    },
  ]
  if (isSubchef) {
    calls.push(
      // Reward token decimals
      {
        address: isOtherReward.rewardToken.address,
        name: 'decimals',
      },
      // Reward token balance in the reward LP contract
      {
        address: isOtherReward.rewardToken.address,
        name: 'balanceOf',
        params: [getAddress(isOtherReward.rewardLpAddress)],
      },
    )
  }
  const res = await multicall(erc20, calls)
  return {
    tokenBalanceLP: res[0],
    quoteTokenBalanceLP: res[1],
    lpTokenBalanceMC: res[2],
    lpTotalSupply: res[3],
    tokenDecimals: res[4],
    quoteTokenDecimals: res[5],
    rewardTokenDecimals: isSubchef ? res[6] : null,
    rewardTokenBalanceLP: isSubchef ? res[7] : null,
  }
}

const chefCalls = async (farm: SerializedFarm) => {
  const { pid, isSubchef, isOtherReward } = farm
  const pID = isSubchef ? farm.isOtherReward.subPid : pid
  const chefAddress = isSubchef ? getAddress(isOtherReward.subChef) : getMasterChefAddress()
  const chefABI = isSubchef ? subchefABI : masterchefABI

  if (!(pID || pID === 0)) return { info: null, totalAllocPoint: null, resRewardPerSec: null }

  const calls = [
    {
      address: chefAddress,
      name: 'poolInfo',
      params: [pID],
    },
    {
      address: chefAddress,
      name: 'totalAllocPoint',
    },
  ]
  if (isSubchef) {
    calls.push({
      address: chefAddress,
      name: 'rewardPerSecond',
    })
  }
  const res = await multicall(chefABI, calls)
  return {
    info: res[0],
    totalAllocPoint: res[1],
    resRewardPerSec: isSubchef ? res[2] : null,
  }
}

type PublicFarmData = {
  tokenAmountTotal: SerializedBigNumber
  lpTotalInQuoteToken: SerializedBigNumber
  lpTotalSupply: SerializedBigNumber
  tokenPriceVsQuote: SerializedBigNumber
  poolWeight: SerializedBigNumber
  multiplier: string
  // impermanentLossProtection: boolean
  rewardPerSec?: SerializedBigNumber
  rewardTokenPriceVsQuote?: SerializedBigNumber
}

const fetchFarm = async (farm: SerializedFarm): Promise<PublicFarmData> => {
  const { isSubchef } = farm

  const {
    tokenBalanceLP,
    quoteTokenBalanceLP,
    lpTokenBalanceMC,
    lpTotalSupply,
    tokenDecimals,
    quoteTokenDecimals,
    rewardTokenDecimals,
    rewardTokenBalanceLP,
  } = await erc20calls(farm)

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
  const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))

  // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

  // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))

  const { info, totalAllocPoint, resRewardPerSec } = await chefCalls(farm)

  const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
  const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ZERO

  const tempMultiplier = new BigNumber(allocPoint).div(new BigNumber(totalAllocPoint))

  const rewardPerSec = isSubchef
    ? new BigNumber(resRewardPerSec[0]._hex).div(BIG_TEN.pow(rewardTokenDecimals)).times(tempMultiplier)
    : undefined
  const totalRewardTokenAmount = isSubchef
    ? new BigNumber(rewardTokenBalanceLP).div(BIG_TEN.pow(rewardTokenDecimals))
    : undefined

  return {
    tokenAmountTotal: tokenAmountTotal.toJSON(),
    lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
    poolWeight: poolWeight.toJSON(),
    multiplier: `${allocPoint.div(100).toString()}X`,
    // impermanentLossProtection: isSubchef ? false : info?.impermanentLossProtection,
    rewardPerSec: isSubchef ? rewardPerSec.toJSON() : undefined,
    rewardTokenPriceVsQuote: isSubchef ? quoteTokenAmountTotal.div(totalRewardTokenAmount).toJSON() : undefined,
  }
}

export default fetchFarm
