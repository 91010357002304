import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absence of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  // {
  //   pid: null,
  //   lpSymbol: 'GRIM-NEON LP',
  //   lpAddresses: {
  //     245022934: '',
  //     250: '0x16779D9d7f0bb5ab6fe3D9F715bCbed4439Fb7e0',
  //   },
  //   isSubchef: false,
  //   token: serializedTokens.grim,
  //   quoteToken: serializedTokens.wbnb,
  // },
]
export default priceHelperLps
