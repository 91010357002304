import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const nftfarms: SerializedFarmConfig[] = [
  // {
  //   pid: 0,
  //   lpSymbol: 'GLOW-PLUG LP',
  //   lpAddresses: {
  //     245022934: '',
  //     245022926: '0x501f1BD097D1B844119C737DcC46Dde1fDe08673',
  //   },
  //   isSubchef: true,
  //   isOtherReward: {
  //     subChef: {
  //       245022934: '',
  //       245022926: '0x4f8452A33E93Df9c5915ADcB608A5d5948B3bd7A',
  //     },
  //     nft: {
  //       245022934: '',
  //       245022926: '0xff19e7584C5F0C97123117c4b10be0D93c369579',
  //     },
  //     subPid: 0,
  //     earnToken: 'PLUG',
  //     rewardToken: serializedTokens.plug,
  //     rewardLpAddress: {
  //       245022934: '',
  //       245022926: '0x31DE68dc92FdbbE0047ecB5220250Ba88d9E695c',
  //     },
  //     rewardPerSec: '2',
  //   },
  //   token: serializedTokens.plug,
  //   quoteToken: serializedTokens.cake,
  // },
]

export default nftfarms
