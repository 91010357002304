/* eslint-disable prefer-destructuring */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchLockerData, fetchPairInfo, fetchTotalLockers } from './fetchLockerData'
import { fetchLockerUserData } from './fetchLockerUserData'
import { LockerState } from './types'

const initialState: LockerState = {
  lockers: {},
  formState: {
    isLoading: false,
    error: null,
    address: '',
  },
}

export const fetchLockerDetailsAsync = createAsyncThunk('locker/fetchLockerDetails', async (lockId: number) => {
  const res = await fetchLockerData(lockId)
  return res
})

// Async thunks
export const fetchLockersAsync = createAsyncThunk('locker/fetchLockers', async (_, { dispatch }) => {
  const totalLockers = await fetchTotalLockers()
  const end = totalLockers

  // eslint-disable-next-line for-direction
  for (let i = 0; i < end; i += 1) {
    dispatch(fetchLockerDetailsAsync(i))
  }
})

export const fetchLockerAsync = createAsyncThunk('locker/fetchLockers', async (lockId: string, { dispatch }) => {
  dispatch(fetchLockerDetailsAsync(Number(lockId)))
})

export const fetchAddressDetails = createAsyncThunk(
  'locker/fetchAddressDetails',
  async ({ account, address }: { account: string; address: string }) => {
    const info = await fetchPairInfo(address)
    const userData = await fetchLockerUserData(account, address)

    return { info, userData }
  },
)

export const reFetchAddressUserDetails = createAsyncThunk(
  'locker/reFetchAddressUserDetails',
  async ({ account, address }: { account: string; address: string }) => {
    const userData = await fetchLockerUserData(account, address)
    return { userData }
  },
)

export const setAddress = createAsyncThunk(
  'locker/setAddress',
  async ({ account, address }: { account: string; address: string }, { dispatch }) => {
    dispatch(fetchAddressDetails({ account, address }))
    return address
  },
)

export const lockerSlice = createSlice({
  name: 'Locker',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLockerDetailsAsync.pending, (state, { meta: { arg } }) => {
      if (!state.lockers[arg]) state.lockers[arg] = {}
    })
    builder.addCase(fetchLockerDetailsAsync.fulfilled, (state, { payload, meta: { arg } }) => {
      state.lockers[arg].notFound = false
      state.lockers[arg].lockerInfo = payload
    })
    builder.addCase(fetchLockerDetailsAsync.rejected, (state, { meta: { arg } }) => {
      state.lockers[arg].notFound = true
    })

    builder.addCase(setAddress.fulfilled, (state, { payload }) => {
      state.formState.address = payload
    })

    builder.addCase(fetchAddressDetails.pending, (state) => {
      state.formState.isLoading = true
      state.formState.error = null
      state.formState.info = undefined
    })
    builder.addCase(fetchAddressDetails.fulfilled, (state, { payload }) => {
      state.formState.isLoading = false
      state.formState.info = payload.info
      state.formState.userData = payload.userData
    })
    builder.addCase(fetchAddressDetails.rejected, (state) => {
      state.formState.isLoading = false
      state.formState.error = "Couldn't find a pair with the given address"
    })
    builder.addCase(reFetchAddressUserDetails.fulfilled, (state, { payload }) => {
      state.formState.userData = payload.userData
    })
  },
})

export default lockerSlice.reducer
