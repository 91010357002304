import React from 'react'
import { ModalProvider } from '@pancakeswap/uikit'
import { Web3ReactProvider } from '@web3-react/core'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { getLibrary } from 'utils/web3React'
import { LanguageProvider } from 'contexts/Localization'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { ToastsProvider } from 'contexts/ToastsContext'
import store from 'state'
import { light } from 'style/theme'
// eslint-disable-next-line import/no-unresolved
import { GelatoProvider } from '@gelatonetwork/limit-orders-react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import GelatoThemeProvider from 'views/LimitOrder/GelatoThemeProvider'

const ThemeProviderWrapper = (props) => {
  return <ThemeProvider theme={light} {...props} />
}


function Gelato({ children }: { children?: React.ReactNode }) {
  const { library, chainId, account } = useActiveWeb3React()

  return (
    <GelatoThemeProvider>
      <GelatoProvider
        library={library}
        chainId={chainId}
        account={account ?? undefined}
        // Optionally your can set a specific handler to block trades on a specific handler
        // Currently we offer support out of the box for "uniswap", "quickswap", "spookyswap" and "spiritswap"
        // Please reach out to us if you want to register a custom handler
        // Make sure chainId and handler are valid
        handler="defyswap"
        // [ONLY IF USING COMPONENT] Optionally pass a toggle modal to be able to connect
        // to a wallet via the component button
        // toggleWalletModal={toggleWalletModal}

        // By default `useDefaultTheme`and `useDarkMode` are set to true
        // Optionally, if you can try to use and pass your own theme by setting `useDefaultTheme`={false}
        // as long as it conforms with our theme definitions (you can check our `ThemeProvider` [here](https://github.com/gelatodigital/limit-orders-lib/tree/master/packages/limit-orders-react/theme/index.tsx))
        // Optionally, if your main theme does not comply with our definitions, you can also wrap `GelatoProvider`
        // with a custom `ThemeProvider` with your own custom definitions. (check our `ThemeProvider` as an example)
        useDefaultTheme={false}
        // useDarkMode={false}
      >
        {children}
      </GelatoProvider>
    </GelatoThemeProvider>
  )
}

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <ToastsProvider>
          <HelmetProvider>
            <ThemeProviderWrapper>
              <LanguageProvider>
                <RefreshContextProvider>
                  <ModalProvider>
                    <Gelato>{children}</Gelato>
                  </ModalProvider>
                </RefreshContextProvider>
              </LanguageProvider>
            </ThemeProviderWrapper>
          </HelmetProvider>
        </ToastsProvider>
      </Provider>
    </Web3ReactProvider>
  )
}

export default Providers
