import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'
// eslint-disable-next-line import/no-unresolved
import { gelatoReducers, GELATO_PERSISTED_KEYS } from '@gelatonetwork/limit-orders-react'
import { DEFAULT_LIST_OF_LISTS } from 'config/constants/lists'

import farmsReducer from './farms'
import nftFarmsReducer from './nftFarms'
import poolsReducer from './pools'
import blockReducer from './block'
import infoReducer from './info'
import { updateVersion } from './global/actions'
import user from './user/reducer'
import transactions from './transactions/reducer'
import swap from './swap/reducer'
import mint from './mint/reducer'
import lists from './lists/reducer'
import burn from './burn/reducer'
import multicall from './multicall/reducer'
import launchpadReducer from './launchpad'
import lockerReducer from './locker'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists', 'profile', ...GELATO_PERSISTED_KEYS]

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    block: blockReducer,
    farms: farmsReducer,
    pools: poolsReducer,
    info: infoReducer,
    nftFarms: nftFarmsReducer,
    launchpad: launchpadReducer,
    locker: lockerReducer,

    // Exchange
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,

    // Gelato limit order
    ...gelatoReducers,
  },
  middleware: [...getDefaultMiddleware({ thunk: true, serializableCheck: false }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

store.dispatch(updateVersion())

// Add token list to gelato limit order
store.dispatch({ type: 'glists/enableList', payload: DEFAULT_LIST_OF_LISTS[0] })

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()

export default store
