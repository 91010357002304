import React, { ReactElement } from 'react'
import { SubMenuItemsType, FooterLinkType, Colors, FlexProps, BoxProps, SvgProps } from '@pancakeswap/uikit'

export interface Language {
  code: string
  language: string
  locale: string
}

export interface LinkStatus {
  text: string
  color: keyof Colors
}
export enum DropdownMenuItemType {
  INTERNAL_LINK = 0,
  EXTERNAL_LINK = 1,
  BUTTON = 2,
  DIVIDER = 3,
}
export interface DropdownMenuItems {
  label?: string | React.ReactNode
  href?: string
  onClick?: () => void
  type?: DropdownMenuItemType
  status?: LinkStatus
  disabled?: boolean
}

export declare type MenuItemsType = {
  label: string
  href: string
  icon?: React.FC<SvgProps>
  showOnMobile?: boolean
  showItemsOnMobile?: boolean
  items?: MenuItemsType[]
  type?: DropdownMenuItemType
}

export interface NavProps {
  userMenu?: ReactElement
  globalMenu?: ReactElement
  links: Array<MenuItemsType>
  subLinks: Array<SubMenuItemsType>
  footerLinks: Array<FooterLinkType>
  activeItem: string
  activeSubItem: string
  isDark: boolean
  toggleTheme: (isDark: boolean) => void
  cakePriceUsd?: number
  currentLang: string
  buyCakeLabel: string
  langs: Language[]
  setLang: (lang: Language) => void
}

export type FooterProps = {
  items: FooterLinkType[]
  buyCakeLabel: string
  isDark: boolean
  toggleTheme: (isDark: boolean) => void
  cakePriceUsd?: number
  currentLang: string
  langs: Language[]
  setLang: (lang: Language) => void
} & FlexProps

declare const scales: {
  readonly MD: 'md'
  readonly SM: 'sm'
  readonly XS: 'xs'
}
export type Scale = typeof scales[keyof typeof scales]

export type Position = 'top' | 'top-right' | 'bottom'

export interface MenuItemsProps extends BoxProps {
  items: MenuItemsType[]
  activeItem?: string
  activeSubItem?: string
}

export type MenuItemVariant = 'default' | 'subMenu'

export interface MenuItemProps {
  isActive?: boolean
  href: string
  variant?: MenuItemVariant
  statusColor?: keyof Colors
  external?: boolean
}

export type StyledMenuItemProps = {
  $isActive?: boolean
  $variant?: MenuItemVariant
  $statusColor?: keyof Colors
}

export interface BottomNavProps extends BoxProps {
  items: MenuItemsType[]
  activeItem?: string
  activeSubItem?: string
}

export interface BottomNavItemProps {
  label: string
  href: string
  iconName?: React.FC<SvgProps>
  isActive?: boolean
  showItemsOnMobile?: boolean
}
