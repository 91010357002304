import styled from 'styled-components'
import { Button } from '@pancakeswap/uikit'

export default styled(Button)<{ disabled: boolean }>`
  ${({ variant, theme, disabled }) =>
    (variant === 'primary' || variant === 'secondary' || variant === undefined) &&
    `
    border-radius: 50rem;
  border-color: ${theme.colors.primary};
  border-style: solid;
  border-width: 4px;
  box-shadow: ${theme.card.boxShadow};

  ${
    !disabled &&
    `
  &:hover {
    opacity: 1 !important;
    background-color: ${theme.colors.primary}33;
    color: ${theme.isDark ? '#fff' : theme.colors.primary};
  }`
  }
  `}
`
