import BigNumber from 'bignumber.js'
import pairAbi from 'config/abi/pair.json'
import lockerAbi from 'config/abi/locker.json'

import moment from 'moment'
import { getBalanceAmount } from 'utils/formatBalance'
import { Call, multicallv2 } from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import contracts from 'config/constants/contracts'
import { explorerLink } from 'config/constants/locker'
import { LockerInfo, PairInfo, PairTokenInfo } from './types'

const lockerAddress = getAddress(contracts.locker)

export async function fetchTotalLockers() {
  const calls: Call[] = [
    {
      address: lockerAddress,
      name: 'totalLockers',
    },
  ]
  const [_totalLockers] = await multicallv2(lockerAbi, calls)
  // console.log({ _totalLockers })
  return new BigNumber(_totalLockers).toNumber()
}

export async function fetchLockerData(lockId: number): Promise<LockerInfo> {
  const calls: Call[] = [
    {
      address: lockerAddress,
      name: 'lockInfo',
      params: [lockId],
    },
  ]
  const [_lockInfo] = await multicallv2(lockerAbi, calls)
  // console.log({ _lockInfo })

  const lpAddress = _lockInfo.token
  const pairInfo = await fetchPairInfo(lpAddress)
  // console.log({ pairInfo })

  const { lpDecimals } = pairInfo
  const lpAmount = getBalanceAmount(new BigNumber(_lockInfo.amount._hex), lpDecimals)
  const lockEnd = moment.unix(new BigNumber(_lockInfo.unlockTime._hex).toNumber())

  return {
    lpAddress,
    lpAddressExplorerLink: explorerLink + lpAddress,
    lpAmount,
    lockPercentage: new BigNumber(lpAmount).multipliedBy(100).dividedBy(pairInfo.totalSupply).toNumber(),

    lockEnd: lockEnd.unix(),
    timeToEnd: lockEnd.diff(moment(), 'seconds'),

    owner: _lockInfo.owner,
    ownerExplorerLink: explorerLink + _lockInfo.owner,
    logoUrl: _lockInfo.logoUrl,

    released: _lockInfo.withdrawn,

    pairInfo,
  }
}

export async function fetchPairInfo(lpAddress: string): Promise<PairInfo> {
  const calls: Call[] = [
    {
      address: lpAddress,
      name: 'decimals',
    },
    {
      address: lpAddress,
      name: 'totalSupply',
    },
  ]
  const [_lpDecimals, _totalSupply] = await multicallv2(pairAbi, calls)
  // console.log({ _lpDecimals, _totalSupply })

  const tokenData = await fetchTokenData(lpAddress)

  const lpDecimals = _lpDecimals[0]
  return {
    lpDecimals,
    totalSupply: getBalanceAmount(new BigNumber(_totalSupply), lpDecimals),
    ...tokenData,
  }
}

async function fetchTokenData(lpAddress: string): Promise<PairTokenInfo> {
  try {
    const calls: Call[] = [
      {
        address: lpAddress,
        name: 'token0',
      },
      {
        address: lpAddress,
        name: 'token1',
      },
    ]
    const [_token0, _token1] = await multicallv2(pairAbi, calls, { requireSuccess: false })
    // console.log({ _token0, _token1 })

    if (_token0 === null) {
      throw new Error('No token0')
    }

    const calls2: Call[] = [
      {
        address: _token0[0],
        name: 'symbol',
      },
      {
        address: _token1[0],
        name: 'symbol',
      },
    ]
    const [_token0Symbol, _token1Symbol] = await multicallv2(pairAbi, calls2)
    // console.log({ _token0Symbol, _token1Symbol })

    return {
      type: 'pair',
      name: `${_token0Symbol[0]}-${_token1Symbol[0]}`,
      token0: {
        address: _token0[0],
        symbol: _token0Symbol[0],
        explorerLink: explorerLink + _token0,
      },
      token1: {
        address: _token1[0],
        symbol: _token1Symbol[0],
        explorerLink: explorerLink + _token1,
      },
    }
  } catch {
    const calls: Call[] = [
      {
        address: lpAddress,
        name: 'symbol',
      },
    ]
    const [_symbol] = await multicallv2(pairAbi, calls)
    return {
      name: _symbol[0],
      type: 'single',
      token0: {
        address: lpAddress,
        symbol: _symbol[0],
        explorerLink: explorerLink + lpAddress,
      },
    }
  }
}
