import { Call, multicallv2 } from 'utils/multicall'
import BigNumber from 'bignumber.js'

import pairAbi from 'config/abi/pair.json'
import { getBalanceAmount, getDecimalAmount } from 'utils/formatBalance'
import { getAddress } from 'utils/addressHelpers'
import contracts from 'config/constants/contracts'
import { LockerUserData } from './types'

const lockerAddress = getAddress(contracts.locker)

export async function fetchLockerUserData(account: string, lpAddress: string): Promise<LockerUserData> {
  const calls: Call[] = [
    {
      address: lpAddress,
      name: 'decimals',
    },
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [account],
    },
    {
      address: lpAddress,
      name: 'allowance',
      params: [account, lockerAddress],
    },
  ]
  const [_lpDecimals, _balance, _allowance] = await multicallv2(pairAbi, calls)
  // console.log({ _balance: new BigNumber(_balance).toString(), _allowance: new BigNumber(_allowance).toString() })
  const lpDecimals = _lpDecimals[0]

  return {
    balance: getBalanceAmount(new BigNumber(_balance), lpDecimals),
    allowed: new BigNumber(_allowance).isGreaterThanOrEqualTo(new BigNumber(_balance)),
  }
}
