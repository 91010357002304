import React, { useState, useCallback, useEffect } from 'react'
import { FallingBunnies, FallingBunniesProps, useKonamiCheatCode } from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'

const EasterEgg: React.FC<FallingBunniesProps> = (props) => {
  const [show, setShow] = useState(false)
  const startFalling = useCallback(() => setShow(true), [setShow])
  useKonamiCheatCode(startFalling)

  // const { toastError } = useToast()
  // useEffect(() => {
  //   if (show) {
  //     toastError('This is a sample error message')
  //   }
  // }, [show, toastError])

  if (show) {
    return (
      <div onAnimationEnd={() => setShow(false)}>
        <FallingBunnies {...props} />
      </div>
    )
  }
  return null
}

export default React.memo(EasterEgg)
