import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import nftFarmsConfig from 'config/constants/nftFarms'
import { farmsConfig } from 'config/constants'
import fetchFarms from 'state/farms/fetchFarms'

import isArchivedPid from 'utils/farmHelpers'
import fetchNFTFarms from './fetchFarms'
import fetchFarmsPrices from './fetchFarmsPrices'
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
  fetchNFTBoost,
  fetchIsNFTApproved,
  fetchStakedNFTIds,
} from './fetchNFTFarmUser'
import { SerializedFarmsState, SerializedFarm } from '../types'

const noAccountFarmConfig = nftFarmsConfig.map((farm) => ({
  ...farm,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0',
    // daysSinceDeposit: '0',
  },
}))

const initialState: SerializedFarmsState = {
  data: noAccountFarmConfig,
  loadArchivedFarmsData: false,
  userDataLoaded: false,
}

export const nonArchivedFarms = nftFarmsConfig.filter(({ pid }) => !isArchivedPid(pid))

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<SerializedFarm[], number[]>(
  'nftFarms/fetchFarmsPublicDataAsync',
  async (pids) => {
    const nftFarmsToFetch = nftFarmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
    const nftFarms = await fetchNFTFarms(nftFarmsToFetch)

    const farmsToFetch = farmsConfig.slice(1, 3)
    const farms = await fetchFarms(farmsToFetch)

    const farmsWithPrices = await fetchFarmsPrices(nftFarms, farms)
    return farmsWithPrices
  },
)

interface FarmUserDataResponse {
  pid: number
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
  // daysSinceDeposit: string
  // extraGlow: string
  nftBoost?: string
  isNFTApproved?: boolean
}

export const fetchFarmUserDataAsync = createAsyncThunk<FarmUserDataResponse[], { account: string; pids: number[] }>(
  'nftFarms/fetchFarmUserDataAsync',
  async ({ account, pids }) => {
    const farmsToFetch = nftFarmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))

    const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch)
    const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch)
    const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch)
    const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch)
    const userNFTBoost = await fetchNFTBoost(account, farmsToFetch)
    const userNFTApproved = await fetchIsNFTApproved(account, farmsToFetch)
    const userStakedNFTIds = await fetchStakedNFTIds(account, farmsToFetch)

    return userFarmAllowances.map((farmAllowance, index) => {
      return {
        pid: farmsToFetch[index].pid,
        allowance: userFarmAllowances[index],
        tokenBalance: userFarmTokenBalances[index],
        stakedBalance: userStakedBalances[index],
        earnings: userFarmEarnings[index],
        nftBoost: userNFTBoost[index],
        isNFTApproved: userNFTApproved[index],
        stakedNFT: userStakedNFTIds[index],
      }
    })
  },
)

export const farmsSlice = createSlice({
  name: 'NFTFarms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((farm) => {
        const liveFarmData = action.payload.find((farmData) => farmData.pid === farm.pid)
        return { ...farm, ...liveFarmData }
      })
    })

    // Update farms with user data
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.data.findIndex((farm) => farm.pid === pid)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
  },
})

export default farmsSlice.reducer
