import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationDot, Box, Flex, Text, Dropdown, OpenNewIcon, DropdownMenuItemType } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { BottomNavItemProps, BottomNavProps } from './types'
import { MenuItem } from './MenuItems'

const StyledBottomNavItem = styled.button`
  display: block;
  border: 0;
  background: transparent;
  cursor: pointer;
  height: 44px;
  padding: 4px 12px;
  &:hover {
    border-radius: 16px;
  }
  &:hover,
  &:hover div {
    background: ${({ theme }) => theme.colors.tertiary};
  }
`

const StyledBottomNavText = styled(Text)`
  display: -webkit-box;
  overflow: hidden;
  user-select: none;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
`

const BottomNavItem: React.FC<BottomNavItemProps> = ({
  label,
  iconName: Icon,
  href,
  showItemsOnMobile = false,
  isActive = false,
  ...props
}) => {
  const bottomNavItemContent = (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
      {/* Hack for md icons */}
      {Icon && Icon.name.startsWith('Md') && (
        <Icon height="22px" width="21px" color={isActive ? 'secondary' : 'textSubtle'} />
      )}
      {Icon && !Icon.name.startsWith('Md') && (
        <Icon height="18px" width="21px" color={isActive ? 'secondary' : 'textSubtle'} />
      )}
      <StyledBottomNavText
        color={isActive ? 'text' : 'textSubtle'}
        fontWeight={isActive ? '600' : '400'}
        fontSize="10px"
      >
        {label}
      </StyledBottomNavText>
    </Flex>
  )

  return showItemsOnMobile ? (
    <StyledBottomNavItem type="button" {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  ) : (
    <StyledBottomNavItem as={Link} to={href} {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  )
}

const StyledBottomNav = styled(Flex)`
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 5px 8px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border-top: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding-bottom: env(safe-area-inset-bottom);
  html[data-useragent*='TokenPocket_iOS'] & {
    padding-bottom: 45px;
  }
`

const BottomNav: React.FC<BottomNavProps> = ({ items = [], activeItem = '', activeSubItem, ...props }) => {
  return (
    <StyledBottomNav justifyContent="space-around" {...props}>
      {items.map(({ label, href, icon, showOnMobile = true, showItemsOnMobile = true, items: subItems }) => {
        if (!showOnMobile) return null

        const isActive = activeItem === href
        return subItems ? (
          <Dropdown
            position="top"
            target={
              <Box key={label}>
                <NotificationDot>
                  <BottomNavItem
                    href={href}
                    isActive={isActive}
                    label={label}
                    iconName={icon}
                    showItemsOnMobile={showItemsOnMobile}
                  />
                </NotificationDot>
              </Box>
            }
          >
            {subItems.map(({ label: subLabel, href: subHref, icon: SubIcon, type }) => {
              const isSubActive = activeSubItem === subHref

              return (
                <MenuItem
                  key={subLabel}
                  href={subHref}
                  isActive={isSubActive}
                  external={type && type === DropdownMenuItemType.EXTERNAL_LINK}
                >
                  <Flex justifyContent="space-between" minWidth="200px">
                    {subLabel || <SubIcon color={isSubActive ? 'secondary' : 'textSubtle'} />}
                    {type && type === DropdownMenuItemType.EXTERNAL_LINK && <OpenNewIcon />}
                  </Flex>
                </MenuItem>
              )
            })}
          </Dropdown>
        ) : (
          <Box key={label}>
            <NotificationDot>
              <BottomNavItem
                href={href}
                isActive={isActive}
                label={label}
                iconName={icon}
                showItemsOnMobile={showItemsOnMobile}
              />
            </NotificationDot>
          </Box>
        )
      })}
    </StyledBottomNav>
  )
}

export default BottomNav
