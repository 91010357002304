export default {
  masterChef: {
    245022934: '0xF1f76dcCf075E8490809b9841238f697f3D9A1b4',
    245022926: '0x261d0B0F4E7E8BAe5D4d55516F651e2b5F86c9AC',
    56: '',
    1: '',
  },
  multiCall: {
    245022934: '0xD4C67ff3ea16Aec71c319bE528C0aeA8F5BEd1e7',
    245022926: '0xEb356DAFEcBAFC212C006183F13e312AA1f83B0F',
    56: '',
    1: '',
  },
  cakeVault: {
    245022934: '0x90DD48409fD2eEec8bB0179f1D9bc18eCFbF6958',
    245022926: '0x0000000000000000000000000000000000000000',
    56: '',
    1: '',
  },
  chainlinkOracle: {
    245022934: '0xe04676B9A9A2973BCb0D1478b5E1E9098BBB7f3D',
    245022926: '',
    56: '',
    1: '',
  },
  nft: {
    245022934: '',
    245022926: '0x0000000000000000000000000000000000000000',
    56: '',
    1: '',
  },
  presale: {
    245022934: '',
    245022926: '0x0000000000000000000000000000000000000000',
    56: '',
    1: '',
  },
  privateSale: {
    245022934: '',
    245022926: '0x0000000000000000000000000000000000000000',
    56: '',
    1: '',
  },
  vesting: {
    245022934: '',
    245022926: '0x0000000000000000000000000000000000000000',
    56: '',
    1: '',
  },
  locker: {
    245022934: '',
    245022926: '0x24d0370B68c4b153418E4bFF32272D1F623E2464',
    56: '',
    1: '',
  },
}
