import tokens from './tokens'
import { PresaleConfig } from './types'

export const presaleConfigs: PresaleConfig[] = [
  {
    token: tokens.glow,
    saleType: 'public',

    presale: {
      isEchSale: true,
      qToken: tokens.busd,
      address: {
        245022926: '0x1AB1059B8205EF06d6d2448c1Dd598ECBAc54A9e',
        245022934: '',
      },
    },

    privateSale: {
      isEchSale: true,
      qToken: tokens.busd,
      address: {
        245022926: '0xb27633Afbd125EEE7aD52744f66f523154B7Ec05',
        245022934: '',
      },
      vestingEnabled: true,
      vestingAddress: {
        245022926: '0xD45043Fcfc068850F15D826214C1D0BE4507f84F',
        245022934: '',
      },
    },

    project: {
      description:
        'EchelonLand is a Marketplace & NFT Staking Reward platform in the Echelon chain releasing 1000 Unique LandNFTs for everyone to collect and stake their NFT!',
      website: 'https://echelonland.app',
      audit: 'none',
      social: {
        twitter: 'http://twitter.com/EchelonLandNFT',
        telegram: 'http://t.me/echelonland',
        medium: 'https://medium.com/@echelonland.app',
      },

      totalSupply: '1,000,000,000',
      presaleTokens: '5,495,000,000',
      liquidityTokens: '5,495,000,000 (MAX)',
      presaleGoal: '5,495 + 1,000 NEON',
      publicPresalePrice: '900 eLAND/NEON',
      privatePresalePrice: '1000 eLAND/NEON',
      listingPrice: '1,000 eLAND/NEON',
      contribution: '1000 NEON (FIXED)',
      whitelisting: 'Required',
      startTime: 'June 4, 2022 6:00:00 PM UTC',
      endTime: 'June 5, 2022 6:00:00 PM UTC',
    },
  },
]
