import { Address, PresaleConfig, Sale } from 'config/constants/types'
import { getAddress } from 'utils/addressHelpers'
import { Call, multicallv2 } from 'utils/multicall'
import BigNumber from 'bignumber.js'

import presaleAbi from 'config/abi/presale.json'
import vestingAbi from 'config/abi/vesting.json'
import cakeAbi from 'config/abi/cake.json'
import { Token } from '@neonswap/testnet-sdk'
import { getBalanceAmount, getDecimalAmount } from 'utils/formatBalance'
import moment from 'moment'
import { simpleRpcProvider } from 'utils/providers'
import { SaleUserData } from './types'

export async function fetchSaleUserData(
  account: string,
  config: PresaleConfig,
): Promise<{
  presale?: SaleUserData
  privateSale?: SaleUserData
}> {
  let presale: SaleUserData
  let privateSale: SaleUserData

  if (config.saleType === 'public' || config.saleType === 'both') {
    const userInfo = await fetchUserInfo(account, config.presale.address, config.token)
    const userTokenInfo = await fetchUserTokenInfo(account, config.presale)
    presale = { ...userInfo, ...userTokenInfo }
    if (config.presale.vestingEnabled) {
      const vestingUserInfo = await fetchVestingUserInfo(account, config.presale, config.token)
      presale.vesting = vestingUserInfo
      privateSale.vesting = vestingUserInfo
      privateSale.startTime = vestingUserInfo.startTime
      privateSale.timeToStart = vestingUserInfo.timeToStart
    }
  }

  if (config.saleType === 'private' || config.saleType === 'both') {
    const userInfo = await fetchUserInfo(account, config.privateSale.address, config.token)
    const userTokenInfo = await fetchUserTokenInfo(account, config.privateSale)
    privateSale = { ...userInfo, ...userTokenInfo }
    if (config.privateSale.vestingEnabled) {
      const vestingUserInfo = await fetchVestingUserInfo(account, config.privateSale, config.token)
      privateSale.vesting = vestingUserInfo
      privateSale.startTime = vestingUserInfo.startTime
      privateSale.timeToStart = vestingUserInfo.timeToStart
    }
  }
  // console.log({ presale, privateSale })
  return {
    presale,
    privateSale,
  }
}

async function fetchUserTokenInfo(account: string, sale: Sale): Promise<Pick<SaleUserData, 'balance' | 'allowed'>> {
  if (sale.isEchSale) {
    const _balance = await simpleRpcProvider.getBalance(account)
    return {
      balance: getBalanceAmount(new BigNumber(_balance._hex), sale.qToken.decimals),
      allowed: true,
    }
  }

  const saleAddress = getAddress(sale.address)
  const calls: Call[] = [
    {
      address: sale.qToken.address,
      name: 'balanceOf',
      params: [account],
    },
    {
      address: sale.qToken.address,
      name: 'userInfo',
      params: [account, saleAddress],
    },
  ]
  const [_balance, _allowance] = await multicallv2(cakeAbi, calls)
  // console.log({ _balance, _allowance })

  return {
    balance: getBalanceAmount(new BigNumber(_balance), sale.qToken.decimals),
    allowed: new BigNumber(_allowance).gt(getDecimalAmount(new BigNumber(10_000_000), sale.qToken.decimals)),
  }
}

async function fetchUserInfo(
  account: string,
  sale: Address,
  token: Token,
): Promise<Pick<SaleUserData, 'amount' | 'claimed'>> {
  const saleAddress = getAddress(sale)
  const calls: Call[] = [
    {
      address: saleAddress,
      name: 'userInfo',
      params: [account],
    },
  ]
  const [_userInfo] = await multicallv2(presaleAbi, calls)
  // console.log({ _userInfo })

  const amount = getBalanceAmount(new BigNumber(_userInfo.amount._hex), token.decimals)
  return {
    amount,
    claimed: _userInfo.claimed,
  }
}

async function fetchVestingUserInfo(
  account: string,
  sale: Sale,
  token: Token,
): Promise<
  SaleUserData['vesting'] & {
    startTime: number
    timeToStart: number
  }
> {
  const vestingAddress = getAddress(sale.vestingAddress)
  const saleAddress = getAddress(sale.address)

  const calls: Call[] = [
    {
      address: vestingAddress,
      name: 'userList',
      params: [account],
    },
    {
      address: vestingAddress,
      name: 'duration',
    },
    {
      address: vestingAddress,
      name: 'interval',
    },
    {
      address: vestingAddress,
      name: 'initUnlockPercent',
    },
    {
      address: vestingAddress,
      name: 'pendingTokens',
      params: [account],
    },
    {
      address: vestingAddress,
      name: 'startTimestamp',
    },
  ]
  const [_userList, _duration, _interval, _initUnlockPercent, _pendingTokens, _startTime] = await multicallv2(
    vestingAbi,
    calls,
    {
      requireSuccess: false,
    },
  )
  // console.log({ _userList, _duration, _interval, _initUnlockPercent, _pendingTokens })

  const calls2: Call[] = [
    {
      address: saleAddress,
      name: 'userInfo',
      params: [account],
    },
  ]
  const [_userInfo] = await multicallv2(presaleAbi, calls2)

  const _total = new BigNumber(_userInfo.amount._hex)
  // console.log({  _total })

  const _debt = new BigNumber(_userList.debt._hex)
  const userAmount = _total.multipliedBy(new BigNumber(10_000).minus(new BigNumber(_initUnlockPercent))).div(10_000)

  let participated = false
  let claimAmount
  let remainder
  let daily
  let pending

  if (_total.gt(0)) {
    participated = true

    claimAmount = getBalanceAmount(_total, token.decimals)
    remainder = getBalanceAmount(new BigNumber(_total).minus(_debt), token.decimals)
    daily = getBalanceAmount(userAmount.multipliedBy(_interval).div(new BigNumber(_duration)), token.decimals)
    pending = getBalanceAmount(new BigNumber(_pendingTokens), token.decimals)
  }

  const startTime = moment.unix(new BigNumber(_startTime).toNumber())

  return {
    participated,
    claimAmount,
    remainder,
    daily,
    pending,
    startTime: startTime.unix(),
    timeToStart: startTime.diff(moment(), 'seconds'),
  }
}
