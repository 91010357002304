import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import subchefABI from 'config/abi/nftSubchefABI.json'
import nftABI from 'config/abi/nftABI.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress, getNFTAddress } from 'utils/addressHelpers'
import { SerializedFarmConfig } from 'config/constants/types'
import { BIG_ZERO } from 'utils/bigNumber'

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    const chefAddress = farm.isSubchef ? getAddress(farm.isOtherReward.subChef) : masterChefAddress
    return { address: lpContractAddress, name: 'allowance', params: [account, chefAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const subChefCalls = []
  farmsToFetch.forEach((farm) => {
    const pID = farm.isOtherReward.subPid
    subChefCalls.push({
      address: getAddress(farm.isOtherReward.subChef),
      name: 'userInfo',
      params: [pID, account],
    })
  })
  const rawSubChefResults = await multicall(subchefABI, subChefCalls)
  const rawStakedBalances = [...rawSubChefResults]

  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[1]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const subChefCalls = []
  farmsToFetch.forEach((farm) => {
    const pID = farm.isSubchef ? farm.isOtherReward.subPid : farm.pid
    if (farm.isSubchef && farm.pid !== 1000) {
      subChefCalls.push({
        address: getAddress(farm.isOtherReward.subChef),
        name: 'pendingReward',
        params: [pID, account],
      })
    }
  })
  const rawSubChefResults = await multicall(subchefABI, subChefCalls)
  const rawEarnings = [...rawSubChefResults]

  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })

  return parsedEarnings
}

export const fetchNFTBoost = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const subChefCalls = []
  farmsToFetch.forEach((farm) => {
    const pID = farm.isOtherReward.subPid
    subChefCalls.push({
      address: getAddress(farm.isOtherReward.subChef),
      name: 'userInfo',
      params: [pID, account],
    })
  })
  const rawSubChefResults = await multicall(subchefABI, subChefCalls)
  const rawRes = [...rawSubChefResults]

  const parsedNFTBoost = rawRes.map((res) => {
    const amount = new BigNumber(res[0]._hex)
    const base = new BigNumber(res[1]._hex)
    return `${(amount.gt(0) ? amount.div(base) : BIG_ZERO).toFixed(3).replace(/\.000$/, '')}X`
  })
  return parsedNFTBoost
}

export const fetchIsNFTApproved = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const subChefCalls = []
  farmsToFetch.forEach((farm) => {
    const subchef = farm.isOtherReward.subChef

    subChefCalls.push({
      address: getNFTAddress(),
      name: 'isApprovedForAll',
      params: [account, getAddress(subchef)],
    })
  })

  const rawSubChefResults = await multicall(nftABI, subChefCalls)
  return rawSubChefResults.map((val) => val[0])
}

export const fetchStakedNFTIds = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const subChefCalls = []
  farmsToFetch.forEach((farm) => {
    const PID = farm.isOtherReward.subPid

    subChefCalls.push({
      address: getAddress(farm.isOtherReward.subChef),
      name: 'getUserNfts',
      params: [PID, account],
    })
  })

  const rawSubChefResults = await multicall(subchefABI, subChefCalls)
  return rawSubChefResults.map((val) => val[0].map((id) => new BigNumber(id._hex).toString()))
}
