import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'
import tokens from 'config/constants/tokens'

// Addresses
import {
  getAddress,
  getMasterChefAddress,
  getCakeVaultAddress,
  getChainlinkOracleAddress,
  getMulticallAddress,
  getLockerAddress,
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import masterChef from 'config/abi/masterchef.json'
import subChef from 'config/abi/subchef.json'
import nftSubChef from 'config/abi/nftSubchefABI.json'
import nftAbi from 'config/abi/nftABI.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import cakeVaultAbi from 'config/abi/cakeVault.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import presaleAbi from 'config/abi/presale.json'
import vestingAbi from 'config/abi/vesting.json'
import lockerAbi from 'config/abi/locker.json'
import pairAbi from 'config/abi/pair.json'

import { ChainLinkOracleContract } from './types'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getSouschefContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), signer)
}
export const getSouschefV2Contract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress), signer)
}
export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, tokens.cake.address, signer)
}
export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}
export const getSubchefContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(subChef, address, signer)
}
export const getNftSubchefContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftSubChef, address, signer)
}
export const getCakeVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress(), signer)
}
export const getChainlinkOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer) as ChainLinkOracleContract
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getNFTContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftAbi, address, signer)
}
export const getPresaleContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(presaleAbi, address, signer)
}
export const getVestingContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(vestingAbi, address, signer)
}

export const getLockerContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lockerAbi, getLockerAddress(), signer)
}
export const getPairContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pairAbi, address, signer)
}
