import { Currency, ETHER } from '@neonswap/testnet-sdk'
import React from 'react'
import styled from 'styled-components'
import Logo from './Logo'

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  if (currency === ETHER) {
    return <StyledLogo size={size} srcs={['/images/tokens/NEON.png']} alt="NEON logo" style={style} />
  }

  return (
    <StyledLogo
      size={size}
      srcs={[`/images/tokens/${currency.symbol}.png`]}
      alt={`${currency?.symbol ?? 'token'} logo`}
      style={style}
    />
  )
}
