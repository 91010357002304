import { Dropdown, Flex, OpenNewIcon } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import React from 'react'
import { DropdownMenuItemType, MenuItemProps, MenuItemsProps, StyledMenuItemProps } from './types'

const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;
  ${({ $isActive, $variant, theme }) =>
    $isActive &&
    $variant === 'subMenu' &&
    `
      &:before{
        content: "";
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: ${theme.colors.primary};
        border-radius: 2px 2px 0 0;
      }
    `};
`

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.primary : theme.colors.textSubtle)};
  font-size: 16px;
  font-weight: ${({ $isActive }) => ($isActive ? '600' : '400')};
  ${({ $statusColor, theme }) =>
    $statusColor &&
    `
    &:after {
      content: "";
      border-radius: 100%;
      background: ${theme.colors[$statusColor]};
      height: 8px;
      width: 8px;
      margin-left: 12px;
    }
  `}
  ${({ $variant }) =>
    $variant === 'default'
      ? `
    padding: 0 16px;
    height: 48px;
  `
      : `
    padding: 4px 4px 0px 4px;
    height: 42px;
  `}
  &:hover {
    background: ${({ theme }) => theme.colors.tertiary};
    ${({ $variant }) => $variant === 'default' && 'border-radius: 16px;'};
  }
`

export const MenuItem: React.FC<MenuItemProps> = ({
  children,
  href,
  isActive = false,
  variant = 'default',
  statusColor,
  external,
  ...props
}) => {
  return (
    <StyledMenuItemContainer $isActive={isActive} $variant={variant}>
      {external ? (
        <StyledMenuItem
          as="a"
          href={href}
          target="_blank"
          rel="noreferrer"
          $isActive={isActive}
          $variant={variant}
          $statusColor={statusColor}
          {...props}
        >
          {children}
        </StyledMenuItem>
      ) : (
        <StyledMenuItem
          as={Link}
          to={href}
          $isActive={isActive}
          $variant={variant}
          $statusColor={statusColor}
          {...props}
        >
          {children}
        </StyledMenuItem>
      )}
    </StyledMenuItemContainer>
  )
}

const MenuItems: React.FC<MenuItemsProps> = ({ items = [], activeItem, activeSubItem, ...props }) => {
  return (
    <Flex {...props}>
      {items.map(({ label, href, icon: Icon, items: subItems }) => {
        const isActive = activeItem === href
        return subItems ? (
          <Dropdown
            target={
              <MenuItem key={label} href={href} isActive={isActive}>
                {label || <Icon color={isActive ? 'secondary' : 'textSubtle'} />}
              </MenuItem>
            }
          >
            {subItems.map(({ label: subLabel, href: subHref, icon: SubIcon, type }) => {
              const isSubActive = activeSubItem === subHref

              return (
                <MenuItem
                  key={subLabel}
                  href={subHref}
                  isActive={isSubActive}
                  external={type && type === DropdownMenuItemType.EXTERNAL_LINK}
                >
                  <Flex justifyContent="space-between" minWidth="200px">
                    {subLabel || <SubIcon color={isSubActive ? 'secondary' : 'textSubtle'} />}
                    {type === DropdownMenuItemType.EXTERNAL_LINK && <OpenNewIcon />}
                  </Flex>
                </MenuItem>
              )
            })}
          </Dropdown>
        ) : (
          <MenuItem key={label} href={href} isActive={isActive}>
            {label || <Icon color={isActive ? 'secondary' : 'textSubtle'} />}
          </MenuItem>
        )
      })}
    </Flex>
  )
}

export default MenuItems
