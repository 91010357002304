import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'GLOW',
    lpAddresses: {
      245022934: '',
      245022926: '0xD69891f7e33b0eC98c0A9E9b5831585c32f76042',
    },
    isSubchef: false,
    token: serializedTokens.cake,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 1,
    lpSymbol: 'GLOW-NEON LP',
    lpAddresses: {
      245022934: '',
      245022926: '0x62853C6B403d2612541573f37b17920A69e53BdC',
    },
    isSubchef: false,
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'GLOW-USDC LP',
    lpAddresses: {
      245022934: '',
      245022926: '0xfbE2E90A4500043E4AE398b5C70692A81dFa9DB7',
    },
    isSubchef: false,
    token: serializedTokens.cake,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 3,
    lpSymbol: 'NEON-USDC LP',
    lpAddresses: {
      245022934: '',
      245022926: '0x8CD01cB98bA7A3e78D1d88A37b919F09E6007E42',
    },
    isSubchef: false,
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },

]

export default farms
