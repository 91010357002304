import React from 'react'
import { Flex, FlexProps, Link, TwitterIcon, TelegramIcon, RedditIcon, GithubIcon } from '@pancakeswap/uikit'
import { FaFacebook, FaMediumM, FaDiscord } from 'react-icons/fa'

import { darkColors } from './colors'

const socials = [
  {
    label: 'Twitter',
    Icon: TwitterIcon,
    href: '',
  },
  {
    label: 'Telegram',
    Icon: TelegramIcon,
    href: '',
  },
  {
    label: 'Discord',
    Icon: FaDiscord,
    href: '',
  },
  // {
  //   label: 'Reddit',
  //   Icon: RedditIcon,
  //   href: 'https://www.reddit.com/r/',
  // },

  {
    label: 'Github',
    Icon: GithubIcon,
    href: '',
  },
  // {
  //   label: 'Facebook',
  //   Icon: FaFacebook,
  //   href: 'https://www.facebook.com/',
  // },
  {
    label: 'Medium',
    Icon: FaMediumM,
    href: '',
  },
]

const SocialLinks: React.FC<FlexProps> = ({ ...props }) => (
  <Flex {...props}>
    {socials.map((social, index) => {
      const iconProps = {
        width: '20px',
        color: darkColors.textSubtle,
        style: { cursor: 'pointer', fontSize: 20 },
      }
      const { Icon } = social
      const mr = index < socials.length - 1 ? '24px' : 0
      // if (social.items) {
      //   return (
      //     // <Dropdown key={social.label} position="top" target={<Icon {...iconProps} mr={mr} />}>
      //     //   {social.items.map((item) => (
      //     //     <Link external key={item.label} href={item.href} aria-label={item.label} color="textSubtle">
      //     //       {item.label}
      //     //     </Link>
      //     //   ))}
      //     // </Dropdown>
      //   )
      // }
      return (
        <Link external key={social.label} href={social.href} aria-label={social.label} mr={mr}>
          <Icon {...iconProps} />
        </Link>
      )
    })}
  </Flex>
)

export default React.memo(SocialLinks, () => true)
