import BigNumber from 'bignumber.js'
// import { getAddress} from 'utils/addressHelpers'
// import { useMasterchef , useSubchef } from 'hooks/useContract'
// import { farmsConfig } from 'config/constants'
import { DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL } from 'config'
import getGasPrice from 'utils/getGasPrice'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

export const stakeFarm = async (masterChefContract, pid, amount) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()

  const tx = await masterChefContract.deposit(pid, value, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeFarm = async (masterChefContract, pid, farmPid, amount) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()

  const tx = await masterChefContract.withdraw(pid, value, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

export const harvestFarm = async (masterChefContract, pid, farmPid) => {
  const gasPrice = getGasPrice()
  if (farmPid === 0) {
    const tx = await masterChefContract.withdraw(pid, '0', { ...options, gasPrice })
    const receipt = await tx.wait()
    return receipt.status
  }

  const tx = await masterChefContract.deposit(pid, '0', { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

export const emergencyWithdraw = async (masterChefContract, pid ) => {
  const gasPrice = getGasPrice()
  
    const tx = await masterChefContract.emergencyWithdraw(pid, { ...options, gasPrice })
    const receipt = await tx.wait()
    return receipt.status
  
}
