import { light, dark } from '@pancakeswap/uikit'

// dark.colors.primary = '#e100f8'
// dark.colors.secondary = '#7645d9'
// dark.colors.background = '#EAF9FF'
// dark.colors.textSubtle = '#000000'
// dark.colors.text = '#000000'
// dark.colors.textDisabled = '#A9A9A9'
// dark.colors.inputSecondary = '#A9A9A9'

light.colors.primary = '#ff00dc'
light.colors.secondary = '#fff'
light.colors.background = 'transparent'
light.colors.backgroundAlt = '#060204'
light.colors.backgroundDisabled = '#555555'
light.colors.textSubtle = '#fff'
light.colors.text = '#fff'
light.colors.textDisabled = '#A9A9A9'
light.nav.background = '#030B15'
light.colors.input = '#000'
light.colors.inputSecondary = '#edb8e4'
light.card.background = '#0A0009'
light.colors.cardBorder = '#b50081 '
light.colors.tertiary = '#000'
light.colors.overlay = '#0F0003'
light.colors.gradients.bubblegum = 'linear-gradient(139.73deg, #550343 0%, #320325 100%)'
light.colors.gradients.cardHeader = 'linear-gradient(139.73deg, #150313 0%, #060204 100%)'
light.modal.background = '#0A0009'
light.colors.dropdown = '#0A0009'
light.alert.background = '#000'
light.shadows.focus = '0px 0px 0px 1px #b211af, 0px 0px 0px 4px rgba(237, 3, 255, 0.5)'
light.colors.dropdownDeep = '#100110'
light.colors.failure = '#ed4b66'
light.colors.primaryBright = '#FF42E5'
light.colors.primaryDark = "#C900AE"
light.colors.success = "#8e00ff"
// console.info(light)
export { light, dark }
