import {
  EarnIcon,
  PoolIcon,
  SwapIcon,
  MoreIcon,
  DropdownMenuItemType,
  SellIcon,
  TradeIcon,
  VoteIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import {
  MdLockOutline,
  MdOutlineCurrencyExchange,
  MdOutlineMonetizationOn,
  MdOutlineMoney,
  MdOutlineRocket,
  MdOutlineRocketLaunch,
  MdOutlineSwapHoriz,
  MdOutlineWaves,
  MdRocket,
} from 'react-icons/md'
import { MenuItemsType } from '../uimenu/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Exchange'),
    href: '/swap',
    icon: MdOutlineSwapHoriz,
    showItemsOnMobile: false,
  },
  {
    label: t('Liquidity'),
    href: '/liquidity',
    icon: MdOutlineWaves,
    showItemsOnMobile: false,
  },
  {
    label: 'Earn',
    href: '#',
    icon: EarnIcon,
    hideSubNav: true,
    items: [
      {
        label: t('Farms'),
        href: '/farms',
      },
      {
        label: t('Pools'),
        href: '/pools',
      },
    ],
  },
  // {
  //   label: t('LaunchPad'),
  //   href: '/launchpad',
  //   icon: MdOutlineRocketLaunch,
  //   showItemsOnMobile: false,
  // },
  {
    label: t('Locker'),
    href: '/locker',
    icon: MdLockOutline,
    showItemsOnMobile: false,
  },
  {
    label: '',
    href: '#',
    icon: MoreIcon,
    hideSubNav: true,
    items: [
      // {
      //   label: 'Analytics',
      //   href: '/info',
      // },
      // {
      //   label: t('NFT Staking'),
      //   href: '/nft',
      // },
      // {
      //   label: t('LuanchPad'),
      //   href: '/launchpad',
      // },
      // {
      //   label: t('Locker'),
      //   href: '/locker',
      // },
      {
        label: t('Bridge'),
        href: 'https://neonpass.live/',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Docs'),
        href: 'https://docs.neonswap.io',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
]

export default config
