import React from 'react'
import styled from 'styled-components'
import { Box } from '@pancakeswap/uikit'
import header from 'views/header.jpg'
import Container from '../Layout/Container'
import { PageHeaderProps } from './types'

const Outer = styled(Box)<{ background?: string }>`
  background-image: url(${header});
  background-size: 100%;
  border: none;
  opacity: 0.9;
`

const Inner = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
`

const PageHeader: React.FC<PageHeaderProps> = ({ background, children, ...props }) => (
  <Outer background={background} {...props}>
    <Inner>{children}</Inner>
  </Outer>
)

export default PageHeader
