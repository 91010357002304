import { SerializedFarm } from 'state/types'
import fetchPublicFarmData from './fetchPublicFarmData'

const fetchNFTFarm = async (farm: SerializedFarm): Promise<SerializedFarm> => {
  const farmPublicData = await fetchPublicFarmData(farm)

  return {
    ...farm,
    ...farmPublicData,
    isOtherReward: {
      ...farm.isOtherReward,
      rewardPerSec: farmPublicData.rewardPerSec,
    },
  }
}

export default fetchNFTFarm
