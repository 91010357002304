import React from 'react'
import styled from 'styled-components'
import { Colors, Skeleton, Text } from '@pancakeswap/uikit'

export interface Props {
  color?: keyof Colors
  cakePriceUsd?: number
}

const PriceLink = styled.div`
  display: flex;
  align-items: center;
  img {
    transition: transform 0.3s;
  }
  :hover {
    img {
      transform: scale(1.2);
    }
  }
`

const GlowPrice: React.FC<Props> = ({ cakePriceUsd, color = 'textSubtle' }) => {
  return <Skeleton width={80} height={24} />
  // cakePriceUsd ? (
  //   <PriceLink>
  //     <img
  //       style={{ width: 24, height: 24, marginRight: 8 }}
  //       src={`${process.env.PUBLIC_URL}/images/tokens/GLOW.png`}
  //       alt="GLOW"
  //     />
  //     <Text color={color} bold>{`$${cakePriceUsd.toFixed(3)}`}</Text>
  //   </PriceLink>
  // ) : (
  //   <Skeleton width={80} height={24} />
  // )
}

export default React.memo(GlowPrice)
